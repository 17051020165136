<template>
  <div class="ielts-activity" :style="{height: (courseData.length % 4 == 0 ? (courseData.length / 4) : (Math.floor(courseData.length / 4) + 1)) * 336  + 'px'}">
    <div class="content" v-if="courseData.length>0">
      <div class="lists">
        <CourseCard v-for="(course,index) in courseData"
                    :courseData="course" :key="index"
                    detailPage="courseDetail" :hideLine="hideLine"></CourseCard>
      </div>
    </div>
    <NoContent v-else :data="noContentData"></NoContent>
    <el-pagination
        @size-change="getActivityCourseList"
        @current-change="getActivityCourseList"
        v-if="pagination"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :page-size="pagination.per_page"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
  import * as Api from '@/api/category/category'
  import CourseCard from '@/components/CourseCard/CourseCard.vue'
  import { switchCategory } from '@/utils/utils'
  import NoContent from '@/components/NoContent/NoContent.vue'
  import { useEventBus } from '@/utils/bus.js'
  import { adsPosition } from '@/enums/API'

  export default {
    name: 'Activity',
    components: {
      CourseCard,
      NoContent
    },
    data () {
      return {
        courseData: [],
        currentIndex: '',
        oldIndex: '',
        category_id: 1,
        pagination: {},
        hideLine:{
          thirdLine:{
            left: true
          }
        },
        noContentData: {
          pic: 'course_pic',
          tip: '暂时没有课程~'
        },
        eventBus:undefined
      }
    },
    created(){
      this.eventBus = useEventBus()
      this.category_id = switchCategory(this.$route.path)
    },  
    mounted () {
      this.eventBus.$emit('busGetAd', { position: [adsPosition.OfficialWebsitePopUp], category_id: this.category_id })
      this.getActivityCourseList()
    },
    methods: {
      getActivityCourseList (page = 1) {
        let params = {
          category_id: this.category_id,
          course_type: this.constant.COURSE_TYPE.LIVE_FEE,
          page: page
        }
        Api.shopCourseList(params, (res, meta) => {
          this.courseData = res
          this.pagination = meta
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .ielts-activity {
    width: 1200px;
    margin: 0 auto;

    & > .content {
      margin-top: 40px;

      & > .lists {
        width: 1200px;
        margin: 0 auto;
        float: left;

        & > .course-card:nth-child(4n) {
          margin-right: 0;
        }

       /deep/ .course-card {
          position: relative;
          width: 272px;
          height: 304px;
          float: left;
          margin-right: 37px;
          box-sizing: border-box;
          margin-bottom: 30px;

          & > a {
            border: 1px solid #E6E6E6;
            border-radius: 5px;
            background: #fff;
            position: relative;
            height: 294px;

            & > .top {
              width: 100%;
              height: 153px;
              border-radius: 5px 5px 0 0;
            }

            & > .desc {
              box-sizing: border-box;
              padding: 18px 16px;
              margin: 0;
              height: 140px;

              & > .first-line {
                font-size: 16px;
              }

              & > .second-line {
                font-size: 14px;
                margin-bottom: 30px;
              }
              &>.third-line{
                padding: 0 18px;
              }
            }
          }
        }
      }
    }

    .slideInUp {
      top: -10px;
    }

    .el-pagination {
      margin: 20px 0 50px;
      float: right;
    }
  }
</style>
